
import {
  defineComponent,
  ref,
  provide,
  watch,
  reactive,
  readonly,
  watchEffect,
  nextTick,
  onBeforeUpdate,
  toRefs,
  onMounted,
} from "vue";
import { RouteLocationNormalized, useRoute, useRouter } from "vue-router";
import axios from "@/api/axios";
import { useStore } from "vuex";
import $ from "jquery";
import asyncLoadJs from "@/hooks/useAsyncLoadJs";
import wx from "weixin-sdk-js";
import { wechatLoginMine } from "@/api/wechatLogin";
import Toast from "@/components/UI/Toast/";

export default defineComponent({
  name: "WechatQian",
  setup() {
      const isHeaderHide = ref(false);
    const isMenuHide = ref(false);
    const route = reactive(useRoute());
    const router = useRouter();
    const toRoute = ref<RouteLocationNormalized | null>(null);
    const fromRoute = ref<RouteLocationNormalized | null>(null);


 //  用户登录信息控制

    const userInfo = reactive({});

    function updateUserInfo(data: object) {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          userInfo[key] = data[key];
        }
      }
    }
    function clearUserInfo() {
      Object.keys(userInfo).forEach((item) => {
        delete userInfo[item];
      });
    }
    function getUserInfo() {
      const token = localStorage.getItem("token") || "";

      axios
        .get("/M/User/isLogin", { headers: { token } })
        .then((res) => {
          if (res.data.success) {
            sessionStorage.setItem("isLogin", "true");
            updateUserInfo(res.data.obj);
          } else {
            localStorage.removeItem("token");
            // console.log("token", 123123123123123);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    getUserInfo();
    provide("userInfo", readonly(userInfo));
    provide("UPDATE_USERINFO", updateUserInfo);
    provide("CLEAR_USERINFO", clearUserInfo);

    function loginSuccess() {
      router.push({
        name: toRoute.value?.name as string,
        query: toRoute.value?.query,
        params: toRoute.value?.params,
      });
      isHeaderHide.value = false;
    }

    function updateToken(token: string) {
      localStorage.setItem("token", token);
    }

     function jumpRed() {
      const token = localStorage.getItem("token") || "";
      axios
        .get("/M/Activicy/redPacketWeChatUrlHtml", { headers: { token } })
        .then((res) => {
          if (res.data.success == false || res.data.msg == '登录失败') {
            Toast({
              title: '请先登录',
              type: "error",
            });
            router.push({
              path: "/login",
              query: {
                    redirect: router.currentRoute.value.fullPath,
                  },
            });
          } else {
            window.location.href = res.data.returnUrl;
          }
        })
        .catch((e) => {
          console.log(e);
        });

    }


    return {
        isHeaderHide,
      isMenuHide,
      loginSuccess,
      toRoute,
      fromRoute,
      userInfo,
      jumpRed,
    };
  },
});
