<template>
  <div class="wrapper">
    <!-- Header -->
    <div class="header">
        <!-- <div class="backerror" ></div> -->
        <img style="width: 100%;" @click="jumpRed"  src="https://yingzegbw.oss-cn-beijing.aliyuncs.com/eventbg/redTurn.jpg" alt=""/>
    </div>
</div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  provide,
  watch,
  reactive,
  readonly,
  watchEffect,
  nextTick,
  onBeforeUpdate,
  toRefs,
  onMounted,
} from "vue";
import { RouteLocationNormalized, useRoute, useRouter } from "vue-router";
import axios from "@/api/axios";
import { useStore } from "vuex";
import $ from "jquery";
import asyncLoadJs from "@/hooks/useAsyncLoadJs";
import wx from "weixin-sdk-js";
import { wechatLoginMine } from "@/api/wechatLogin";
import Toast from "@/components/UI/Toast/";

export default defineComponent({
  name: "WechatQian",
  setup() {
      const isHeaderHide = ref(false);
    const isMenuHide = ref(false);
    const route = reactive(useRoute());
    const router = useRouter();
    const toRoute = ref<RouteLocationNormalized | null>(null);
    const fromRoute = ref<RouteLocationNormalized | null>(null);


 //  用户登录信息控制

    const userInfo = reactive({});

    function updateUserInfo(data: object) {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          userInfo[key] = data[key];
        }
      }
    }
    function clearUserInfo() {
      Object.keys(userInfo).forEach((item) => {
        delete userInfo[item];
      });
    }
    function getUserInfo() {
      const token = localStorage.getItem("token") || "";

      axios
        .get("/M/User/isLogin", { headers: { token } })
        .then((res) => {
          if (res.data.success) {
            sessionStorage.setItem("isLogin", "true");
            updateUserInfo(res.data.obj);
          } else {
            localStorage.removeItem("token");
            // console.log("token", 123123123123123);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    getUserInfo();
    provide("userInfo", readonly(userInfo));
    provide("UPDATE_USERINFO", updateUserInfo);
    provide("CLEAR_USERINFO", clearUserInfo);

    function loginSuccess() {
      router.push({
        name: toRoute.value?.name as string,
        query: toRoute.value?.query,
        params: toRoute.value?.params,
      });
      isHeaderHide.value = false;
    }

    function updateToken(token: string) {
      localStorage.setItem("token", token);
    }

     function jumpRed() {
      const token = localStorage.getItem("token") || "";
      axios
        .get("/M/Activicy/redPacketWeChatUrlHtml", { headers: { token } })
        .then((res) => {
          if (res.data.success == false || res.data.msg == '登录失败') {
            Toast({
              title: '请先登录',
              type: "error",
            });
            router.push({
              path: "/login",
              query: {
                    redirect: router.currentRoute.value.fullPath,
                  },
            });
          } else {
            window.location.href = res.data.returnUrl;
          }
        })
        .catch((e) => {
          console.log(e);
        });

    }


    return {
        isHeaderHide,
      isMenuHide,
      loginSuccess,
      toRoute,
      fromRoute,
      userInfo,
      jumpRed,
    };
  },
});
</script>


<style lang="scss" scoped>
.wrapper{
    width: 100%;
    height: 100%;
}

#wrapper:before {
    content: '';
    display: block;
}

.header{
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}

.logo{
    border: solid 1px #ffffff;
    border-radius: 100%;
    background: #ffffff;
    width: 1.867rem;
    height: 1.867rem;
    line-height: 1.867rem;
    border-radius: 50%;
    display: inline-block;
    img{
        width: 150%;
        height: 150%;
        position: relative;
        bottom: 25%;
        right: 25%;
    }
}

.content{
    width: 8.907rem;
    height: 3.893rem;
    border-style: solid;
    border-color: #ffffff;
    border-top-width: 1px;
    border-bottom-width: 1px;
    max-width: 100%;
    display: inline-block;
}

.content::before {
    content: '';
    display: block;
    position: absolute;
    top: calc(70px - 1px);
    left: calc(50% - 1px);
    width: 1px;
    height: calc(40px + 1px);
    background: #ffffff;
}

.inner{
    padding: 30px 0;
    h1{
        font-size: 25px;
        color: #ffffff;
        margin: 0;
        padding: 0;
         margin-bottom: .4rem;
    }
    p{
        font-size: 12px;
        color: #ffffff;
        padding: 0 10px;
    }
}

.backerror{
    width: 144px;
    height: 44px;
        display: inline-block;
    font-size: 12px;
    background: #b9135a;
    color: #fff;
    line-height: 44px;
    border: 1px solid;
    margin-top: 40px;
    position: relative;
    border-radius: 4px;
}



</style>
